import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import _ from "lodash";
import { Column, Row, Spacer } from "../Layout/Layout";
import {
  getTransmitterPeriodicDisables,
  removeTransmitterPeriodicDisables,
} from "../../core/api/transmitters/transmitters";
import useUser from "../../core/user/useUser";
import Table from "../../ui-lib/components/Tables/Table";
import TableCell from "../../ui-lib/components/Tables/TableCell";
import { PeriodicDisable } from "../../core/api/transmitters/types";

import { notifyApiErrors } from "../../core/helpers/helpers";

import { WeekScheduleCell } from "./WeekScheduleCell";
import { CreateTransmitterScheduleModal } from "./CreateTransmitterScheduleModal";
import useTranslations from "../../core/i18n/useTranslations";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";
import {
  breakpoints,
  useWindowDimensions,
} from "../../core/hooks/dimensionProvider";

export declare type TransmitterScheduleActions = {
  open: (transmitterId: number) => void;
};
export interface TransmitterScheduleProps {
  onClose?: () => void;
}

const columns = (t: (key: string) => string) => [
  {
    header: t("Common:time"),
    fieldTemplate: (rowData: PeriodicDisable) => (
      <TableCell
        value={`${_.padStart(
          rowData.beginHour?.toString(),
          2,
          "0"
        )}:${_.padStart(
          rowData.beginMinute?.toString(),
          2,
          "0"
        )} - ${_.padStart(rowData.endHour?.toString(), 2, "0")}:${_.padStart(
          rowData.endMinute?.toString(),
          2,
          "0"
        )}`}
      />
    ),
  },
  {
    header: t("Common:days"),
    fieldTemplate: (rowData: PeriodicDisable) => (
      <WeekScheduleCell data={rowData} />
    ),
  },
  {
    header: t("Table:AlarmCode"),
    fieldTemplate: (rowData: PeriodicDisable) => (
      <TableCell value={rowData.alarmCode ? `# ${rowData.alarmCode}` : ""} />
    ),
  },
];

export const TransmittersScheduleModal = forwardRef<
  TransmitterScheduleActions,
  TransmitterScheduleProps
>((props: TransmitterScheduleProps, ref) => {
  const { onClose } = props;
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [isTransmitterScheduleOpen, setIsTransmitterScheduleOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const active = useRef(false);
  const transmitterId = useRef<number | undefined>(undefined);
  const [periodicDisables, setPeriodicDisables] = useState<PeriodicDisable[]>();
  const { width } = useWindowDimensions();
  const isMobile = width < breakpoints.desktop;

  const closeModal = () => {
    setIsTransmitterScheduleOpen(false);
    setIsLoading(false);
    onClose?.();
  };

  async function loadData() {
    setIsLoading(true);
    try {
      if (transmitterId.current) {
        const result = await getTransmitterPeriodicDisables(
          transmitterId.current,
          authenticatedRequest
        );
        if (active.current) {
          setPeriodicDisables(result.data);
        }
      }
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
    setIsLoading(false);
  }
  const removeSchedule = async (scheduleId: number) => {
    try {
      await removeTransmitterPeriodicDisables(
        transmitterId.current as number,
        scheduleId,
        authenticatedRequest
      );
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
    loadData();
  };
  useImperativeHandle<TransmitterScheduleActions, TransmitterScheduleActions>(
    ref,
    () => {
      const actions: TransmitterScheduleActions = {
        open: function open(id?: number) {
          setIsTransmitterScheduleOpen(true);
          transmitterId.current = id;
          loadData();
        },
      };
      return actions;
    }
  );
  useEffect(() => {
    active.current = true;
    return () => {
      active.current = false;
    };
  }, []);
  useEffect(() => {
    if (isTransmitterScheduleOpen) loadData();
  }, [isTransmitterScheduleOpen]);

  return (
    <PrimeModal
      isOpen={isTransmitterScheduleOpen}
      onClose={closeModal}
      withHeader
      header={t("Transmitters:schedule_modal_title")}
      loading={isLoading}
      contentStyle={{ width: isMobile ? "100vw" : "960px" }}
    >
      <Column type="top" align="start">
        <p>{t("Transmitters:schedule_table_description")}</p>
        <Spacer size={16} />
        <Row type="space" style={{ alignSelf: "stretch" }}>
          <h2>{t("Transmitters:schedule_table_title")}</h2>

          <CreateTransmitterScheduleModal
            transmitterId={transmitterId.current as number}
            onSubmit={loadData}
          />
        </Row>
        <Spacer size={16} />
        <Table<PeriodicDisable>
          columns={columns(t)}
          items={periodicDisables}
          hideEmptyMessage
          noRowsMessage={t("Transmitters:schedule_table_nomatch")}
          showRowHover
          isLoading={isLoading}
          rowActions={[
            {
              icon: "x",
              text: t("Transmitters:schedule_table_remove"),
              onClick: (rowData) => removeSchedule(rowData.id!),
            },
          ]}
          rowActionsFixed
          rowActionsColWidth={45}
        />
      </Column>
    </PrimeModal>
  );
});
