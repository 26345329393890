import { useRef, useState } from "react";
import { notifyApiErrors } from "../../core/helpers/helpers";
import { EditLocationActions, LocationEditForm } from "./LocationEditForm";
import { useOrganizationsTree } from "../../core/api/organizations/organizations";
import { Spacer } from "../Layout/Layout";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";
import useTranslations from "../../core/i18n/useTranslations";

export const CreateNewLocationModal = ({
  onClose,
  isOpened,
  onSubmit,
}: {
  onClose?: () => void;
  isOpened: boolean;
  onSubmit?: (data: any) => void;
}) => {
  const t = useTranslations();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const editLocationRef = useRef<EditLocationActions>(null);
  const { data: organisationTree } = useOrganizationsTree();

  const closeModal = () => {
    onClose?.();
  };

  const submit = async () => {
    setIsSubmitting(true);
    try {
      const result = await editLocationRef.current?.submitForm();
      if (result?.status) {
        onSubmit?.(result.data);
        closeModal();
      }
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors, error.response?.status);
    }
    setIsSubmitting(false);
  };

  return (
    <PrimeModal
      withFooter
      withHeader
      onClose={closeModal}
      isOpen={isOpened}
      header={t("Objects:accessories_add_location_modal_title")}
      loading={isSubmitting}
      submitBtn={{
        text: t("Objects:accessories_add_location_modal_save"),
        onClick: submit,
      }}
      cancelBtn={{ text: t("Common:cancel"), onClick: closeModal }}
      fixedWidth="848px"
    >
      <p>{t("Locations:create_new_description")}</p>

      <Spacer size={16} />

      <LocationEditForm
        ref={editLocationRef}
        organisationTree={organisationTree}
      />
    </PrimeModal>
  );
};
