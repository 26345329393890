import { Dispatch, SetStateAction, useState } from "react";
import useUser from "../../../core/user/useUser";
import { notify } from "../Alerts/Toast";
import { getErrorKey } from "../../../components/Errors/ErrorAlert";
import LoadingSpinner from "../Loading/LoadingSpinner";
import useTranslations from "../../../core/i18n/useTranslations";
import {
  deleteObjectImage,
  patchUpdateObject,
} from "../../../core/api/objects/objects";
import {
  breakpoints,
  useWindowDimensions,
} from "../../../core/hooks/dimensionProvider";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import { EmptyPhoto } from "./EmptyPhoto";
import { UpdateDeletePhoto } from "./UpdateDeletePhoto";

import styles from "./PhotoWrapperWithUpload.module.css";

export interface PhotoWrapperProps {
  objectId: string;
  buttonName: string;
  propertyToContainData: string;
  photoTitle?: string;
  preLoadedImageData?: string;
  alternativeString?: string;
  setThumbnail: Dispatch<SetStateAction<string>>;
}

const PhotoWrapperWithUpload = ({
  objectId,
  buttonName,
  propertyToContainData,
  photoTitle,
  preLoadedImageData,
  alternativeString,
  setThumbnail,
}: PhotoWrapperProps) => {
  const t = useTranslations();
  const { width } = useWindowDimensions();
  const regg = "data:image/jpeg;base64,";
  const photoURL = preLoadedImageData ? `${regg}${preLoadedImageData}` : "";

  const { authenticatedRequest } = useUser();
  const [loading, setLoading] = useState(false);
  const [photoData, setPhotoData] = useState({
    preview: photoURL,
    raw: "",
  });

  const handleUpload = async (e: any) => {
    e.preventDefault();
    if (e.target.files.length) {
      setLoading(true);
      const imageUrl = URL.createObjectURL(e.target.files[0]);
      const imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = async () => {
        if (reader.result) {
          // @ts-ignore
          const imgData = reader.result.replace(regg, "");

          try {
            await patchUpdateObject(
              objectId,
              { [propertyToContainData]: imgData },
              authenticatedRequest
            );
            setPhotoData({
              preview: imageUrl,
              raw: e.target.files[0],
            });
          } catch (error: any) {
            const errorMessage = error.response?.data.errors[0].errorMessage;
            if (errorMessage.includes("input is not a valid")) {
              return notify({
                message: t("Errors:wrong_image_format"),
                variant: "error",
              });
            }
            const errorKey = getErrorKey(error);
            notify({
              message: t(`Errors:${errorKey}`),
              variant: "error",
            });
          } finally {
            setLoading(false);
          }
        }
      };
      reader.readAsDataURL(imageFile);
    }
  };

  const removeImg = async () => {
    setLoading(true);
    try {
      await deleteObjectImage(objectId, authenticatedRequest);
      setPhotoData({ preview: "", raw: "" });
      setThumbnail("");
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {width >= breakpoints.desktop && photoTitle && (
        <p className={styles.buttonTitle}>{photoTitle}</p>
      )}
      {photoData.preview || photoURL ? (
        <UpdateDeletePhoto
          photoURL={photoURL}
          photoData={photoData}
          removeImg={removeImg}
          alternativeString={alternativeString}
          handleUpload={handleUpload}
        />
      ) : (
        <EmptyPhoto buttonName={buttonName} handleUpload={handleUpload} />
      )}
    </>
  );
};

export default PhotoWrapperWithUpload;
