import { useHistory } from "react-router-dom";
import { Column, Spacer } from "../../../../components/Layout/Layout";
import { ResponseCenterDetailsParams } from "../../../../core/api/responsecenters/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import useSharedAlarmReception from "../SharedAlarmReceptionContext/useSharedAlarmReception";
import { GeneralForm } from "./GeneralForm";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import useUser from "../../../../core/user/useUser";
import { ManualSyncWrapper } from "../../../../components/ManualSync/ManualSyncWrapper";

const General = () => {
  const t = useTranslations();
  const history = useHistory();
  const { config } = useUser();
  const { data, update, reload } = useSharedAlarmReception();

  const viserPermission = config?.show.includes("SensioHideFields");

  if (viserPermission) {
    history.replace(`/adminportal/alarm-receptions/${data?.id}/contacts`);
  }

  const onSubmit = async (values: ResponseCenterDetailsParams) => {
    try {
      await update(values);

      notify({
        message: t("AlarmReceptions:edited_success"),
      });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  return (
    <Column style={{ width: "784px" }} type="top" align="start">
      <h1>{t("AlarmReceptions:general_title")}</h1>
      <Spacer size={8} />
      <p style={{ color: "var(--Grey-600)" }}>
        {t("AlarmReceptions:general_description")}
      </p>

      <Spacer size={32} />

      <h2>{t("AlarmReceptions:basic_details")}</h2>
      <p style={{ color: "var(--Grey-600)" }}>
        {t("AlarmReceptions:basic_details_description")}
      </p>

      <Spacer size={16} />

      <GeneralForm defaultValues={{ ...data! }} onSubmit={onSubmit} />

      <ManualSyncWrapper
        clusterData={{
          lastSync: data?.lastClusterSync,
          latestSyncAttempt: data?.lastClusterSyncAttempt,
        }}
        refetchFunc={reload}
      />
    </Column>
  );
};

export default General;
