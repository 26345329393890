import { useRef, useState } from "react";
import classNames from "classnames";
import { OverlayPanel } from "primereact/overlaypanel";
import useTranslations from "../../../core/i18n/useTranslations";
import Button from "../../../ui-lib/components/Button/Button";
import { PopupContextMenuLinkButton } from "../../../ui-lib/components/Menus/PopupContextMenu/PopupContextMenuLinkButton";
import DeleteObjectModal from "./DeleteObjectModal";
import DownloadLinkModal from "./DownloadLinkModal";
import TestAlarmModal from "./TestAlarmModal";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import { requestDeviceLog } from "../../../core/api/objects/objects";
import useUser from "../../../core/user/useUser";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import DateOfDeathModal from "./DateOfDeathModal";
import useSharedObject from "../SharedObjectContext/useSharedObject";
import { ScheduleAbsenceModal } from "./Absences/ScheduleAbsenceModal";
import { CancelAbsenceModal } from "./Absences/CancelAbsenceModal";
import { AbsenceHistory } from "./Absences/AbsenceHistory";
import Divider from "../../../ui-lib/components/Divider/Divider";
import { EndOngoingAbsences } from "./EndOngoingAbsences";
import ContextSaveButton from "../../../components/ContextSaveButton";

const ObjectActions = ({
  active,
  isMobile,
}: {
  active: boolean;
  isMobile: boolean;
}) => {
  const t = useTranslations();
  const { authenticatedRequest, config } = useUser();
  const { data, absenceData, reloadAbsence } = useSharedObject();

  const contextMenuRef = useRef<OverlayPanel>(null);

  const [scheduleAbsenceModalOpen, setScheduleAbsenceModalOpen] =
    useState(false);
  const [absenceHistoryModalOpen, setAbsenceHistoryModalOpen] = useState(false);
  const [cancelAbsenceModalOpen, setCancelAbsenceModalOpen] = useState(false);
  const [isEditAbsence, setIsEditAbsence] = useState(false);
  const [testAlarmOpen, setTestAlarmOpen] = useState(false);
  const [deceasedModalOpen, setDeceasedModalOpen] = useState(false);
  const [sendDowloadLinkOpen, setSendDowloadLinkOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deceasedDate, setDeceasedDate] = useState(data?.dateOfDeath);

  const showOtherBtn = config?.show.some((item) =>
    [
      "UserTestAlarm",
      "DeleteUser",
      "DateOfDeath",
      "UserAbsence",
      "SystemLogAccess",
    ].includes(item)
  );

  const absenceTextToDisplay = (cancel = false) => {
    if (cancel) {
      if (absenceData?.ongoing.length) {
        return t("Objects:end_ongoing_absence");
      }
    }
    return t("Objects:schedule_absence");
  };

  return (
    <>
      {data?.active && scheduleAbsenceModalOpen && (
        <ScheduleAbsenceModal
          header={t("Objects:schedule_the_absence")}
          isOpen={scheduleAbsenceModalOpen}
          ongoingAbsences={
            absenceData && [...absenceData.ongoing, ...absenceData.upcoming]
          }
          defaultValues={isEditAbsence ? absenceData?.ongoing?.[0] : undefined}
          reloadAbsence={reloadAbsence}
          onClose={() => {
            setScheduleAbsenceModalOpen(false);
            setIsEditAbsence(false);
          }}
          adminId={data?.id}
          isOngoing={!!absenceData?.ongoing.length && isEditAbsence}
        />
      )}
      {data && absenceData && (
        <>
          {absenceHistoryModalOpen && (
            <AbsenceHistory
              isMobile={isMobile}
              adminId={data.id}
              absenceData={absenceData}
              isOpen={absenceHistoryModalOpen}
              reloadAbsence={reloadAbsence}
              onClose={() => setAbsenceHistoryModalOpen(false)}
            />
          )}
          {(absenceData?.ongoing ?? []).length > 1 ? (
            <EndOngoingAbsences
              absences={absenceData.ongoing}
              adminId={data.id}
              isOpen={cancelAbsenceModalOpen}
              onClose={() => setCancelAbsenceModalOpen(false)}
              reloadAbsence={reloadAbsence}
            />
          ) : (
            <CancelAbsenceModal
              isOpen={cancelAbsenceModalOpen}
              isEnd={!!absenceData?.ongoing.length}
              onClose={() => setCancelAbsenceModalOpen(false)}
              adminId={data.id}
              absenceId={
                absenceData?.ongoing?.[0]?.id ?? absenceData?.upcoming?.[0]?.id
              }
              reloadAbsence={reloadAbsence}
            />
          )}
        </>
      )}
      {testAlarmOpen && (
        <TestAlarmModal
          isOpen={testAlarmOpen}
          onClose={() => setTestAlarmOpen(false)}
        />
      )}
      {deceasedModalOpen && (
        <DateOfDeathModal
          isOpen={deceasedModalOpen}
          onClose={() => setDeceasedModalOpen(false)}
          deceasedDate={deceasedDate}
          setDeceasedDate={setDeceasedDate}
        />
      )}
      {!isMobile && <ContextSaveButton />}
      {showOtherBtn && (
        <>
          <Button
            type="button"
            variant="secondary"
            image="dots-horizontal"
            text={t("Common:other")}
            className={classNames({ "ml-8": !isMobile })}
            size={isMobile ? "extraSmall" : "small"}
            onClick={(e) => contextMenuRef.current?.toggle(e)}
          />
          <OverlayPanel ref={contextMenuRef} className="actions-overlay">
            {config?.show.includes("UserAbsence") && (
              <>
                {data?.active && (
                  <PopupContextMenuLinkButton
                    id="absenceModalButton"
                    icon="calendar"
                    text={absenceTextToDisplay()}
                    onClick={() => setScheduleAbsenceModalOpen(true)}
                  />
                )}

                {config?.show.includes("UserAbsence") && (
                  <PopupContextMenuLinkButton
                    id="absenceHistory"
                    icon="history"
                    text={t("Objects:absence_history")}
                    onClick={() => setAbsenceHistoryModalOpen(true)}
                  />
                )}

                {absenceData?.ongoing?.length === 1 && data?.active && (
                  <PopupContextMenuLinkButton
                    id="editAbsence"
                    icon="pencil"
                    onClick={() => {
                      setIsEditAbsence(true);
                      setScheduleAbsenceModalOpen(true);
                    }}
                    text={t("Objects:edit_ongoing_absence")}
                  />
                )}
              </>
            )}

            {config?.show.includes("UserAbsence") &&
              !!absenceData?.ongoing.length && (
                <PopupContextMenuLinkButton
                  id="cancelAbsence"
                  icon="calendar_unavailable"
                  onClick={() => setCancelAbsenceModalOpen(true)}
                  text={absenceTextToDisplay(true)}
                />
              )}

            <Divider />

            {config?.show.find((key) => key === "UserTestAlarm") && active && (
              <PopupContextMenuLinkButton
                id="testAlarm"
                icon="beaker"
                text={t("Objects:test_alarm_create")}
                onClick={() => setTestAlarmOpen(true)}
              />
            )}

            {config?.show.includes("SystemLogAccess") &&
              config?.show.includes("NewAdminBeta") && (
                <PopupContextMenuLinkButton
                  id="requestLog"
                  icon="clipboard-list"
                  text={t("Objects:request_logs")}
                  onClick={async () => {
                    if (data) {
                      try {
                        await requestDeviceLog(data.id, authenticatedRequest);

                        notify({
                          message: t("Objects:request_successfully_send"),
                        });
                      } catch (error: any) {
                        notifyApiErrors(
                          error.response?.data?.errors,
                          error.response?.status
                        );
                      }
                    }
                  }}
                />
              )}

            {config?.show.includes("NewAdminBeta") && (
              <>
                <PopupContextMenuLinkButton
                  id="downloadLink"
                  icon="link"
                  text={t("Objects:download_link_send")}
                  onClick={() => setSendDowloadLinkOpen(true)}
                />
                <DownloadLinkModal
                  isOpen={sendDowloadLinkOpen}
                  onClose={() => setSendDowloadLinkOpen(false)}
                />
              </>
            )}

            {config?.show.find((key) => key === "DateOfDeath") && (
              <PopupContextMenuLinkButton
                id="markDeceased"
                icon={!data?.dateOfDeath ? "heart-deceased" : "heart-alive"}
                text={
                  !data?.dateOfDeath
                    ? t("Objects:set_date_of_death")
                    : t("Objects:remove_date_of_death")
                }
                onClick={() => setDeceasedModalOpen(true)}
              />
            )}

            {config?.show.find((key) => key === "DeleteUser") && (
              <>
                <PopupContextMenuLinkButton
                  id="deleteObject"
                  icon="trash"
                  text={t("Objects:delete_object")}
                  onClick={() => setDeleteOpen(true)}
                />
                <DeleteObjectModal
                  isOpen={deleteOpen}
                  onClose={() => setDeleteOpen(false)}
                />
              </>
            )}
          </OverlayPanel>
        </>
      )}
    </>
  );
};

export default ObjectActions;
