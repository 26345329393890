import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

import "./index.css";
import "./ui-lib/styles/setup";

const domNode = document.getElementById("root");
if (!domNode) throw new Error("Failed to find the root element");
const root = createRoot(domNode);

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

reportWebVitals();
