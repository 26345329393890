import useTranslations from "../../../core/i18n/useTranslations";
import Icon from "../Icon";

import styles from "./PhotoWrapperWithUpload.module.css";

export const EmptyPhoto = ({
  buttonName,
  handleUpload,
}: {
  buttonName: string;
  handleUpload: (e: any) => Promise<void>;
}) => {
  const t = useTranslations();
  return (
    <label className={styles.buttonWrapper} htmlFor={buttonName}>
      <div className={styles.buttonContent}>
        <Icon name="photograph" color="Primary-700" />
        <p>{t("Common:upload")}</p>
      </div>
      <input
        type="file"
        id={buttonName}
        className={styles.fileInput}
        onChange={handleUpload}
        accept="image/jpeg"
      />
    </label>
  );
};
