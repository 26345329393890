import { forwardRef, useImperativeHandle, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Column, Row, Spacer } from "../../../components/Layout/Layout";
import { EmergencyCenterSetting } from "../../../core/api/responsecenters/types";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import useTranslations from "../../../core/i18n/useTranslations";
import TextInput from "../../../ui-lib/components/Inputs/TextInput";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";

export declare type EditSettingDialogActions = {
  open: (setting?: EmergencyCenterSetting) => void;
};
export interface EditSettingDialogProps {
  onSubmit?: () => void;
  onClose?: () => void;
  saveButtonTitle: string;
  editSetting: (setting: EmergencyCenterSetting) => Promise<void>;
}
export const EditEmergencyCenterSetting = forwardRef<
  EditSettingDialogActions,
  EditSettingDialogProps
>((props: EditSettingDialogProps, ref) => {
  const { onSubmit, onClose, saveButtonTitle, editSetting } = props;
  const t = useTranslations();
  const [isEditSettingOpen, setIsEditSettingOpen] = useState(false);
  const [settingsData, setSettingsData] = useState<EmergencyCenterSetting>();
  const [title, setTitle] = useState("");

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset,
  } = useForm<EmergencyCenterSetting>({
    defaultValues: settingsData,
    shouldUnregister: true,
  });

  const closeModal = () => {
    setIsEditSettingOpen(false);
    setSettingsData(undefined);
    reset();
    if (onClose !== undefined) {
      onClose();
    }
  };

  const submit = async (data: EmergencyCenterSetting) => {
    try {
      data.id = settingsData?.id;
      await editSetting(data);
      onSubmit?.();
      closeModal();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  useImperativeHandle<EditSettingDialogActions, EditSettingDialogActions>(
    ref,
    () => {
      const actions: EditSettingDialogActions = {
        open: function open(setting?: EmergencyCenterSetting) {
          setSettingsData(setting);
          setIsEditSettingOpen(true);

          if (setting) {
            setTitle(t("AlarmReceptions:edit_setting_title"));
            reset(setting);
          } else {
            setTitle(t("AlarmReceptions:add_setting_title"));
            reset({});
          }
        },
      };
      return actions;
    }
  );

  return (
    <PrimeModal
      withFooter
      withHeader
      header={title}
      onClose={closeModal}
      isOpen={isEditSettingOpen}
      submitBtn={{ text: saveButtonTitle, onClick: handleSubmit(submit) }}
      cancelBtn={{ text: t("Common:cancel"), onClick: closeModal }}
      loading={isSubmitting}
      contentStyle={{ width: "848px" }}
    >
      <p>{t("AlarmReceptions:edit_emergencycenter_setting_description")}</p>
      <Spacer size={16} />
      <form className="mb-4">
        <Column type="top">
          <Row type="fill" align="fill" style={{ alignSelf: "stretch" }}>
            <Controller
              name="setting"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextInput
                  required
                  inputRef={field.ref}
                  css={{ flex: 1 }}
                  placeholder={t(
                    "AlarmReceptions:edit_emergencycenter_setting_placeholder_setting"
                  )}
                  label={t(
                    "AlarmReceptions:advanced_settings_emergencycentersettings_setting"
                  )}
                  value={field.value ?? ""}
                  onChange={field.onChange}
                  validationError={
                    errors.setting &&
                    t(
                      "AlarmReceptions:edit_emergencycenter_setting_validation_setting_required"
                    )
                  }
                />
              )}
            />
            <Spacer size={16} />
            <Controller
              name="value"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextInput
                  required
                  inputRef={field.ref}
                  css={{ flex: 1 }}
                  placeholder={t("Common:enter_value")}
                  label={t(
                    "AlarmReceptions:advanced_settings_emergencycentersettings_value"
                  )}
                  value={field.value ?? ""}
                  onChange={field.onChange}
                  validationError={
                    errors.value &&
                    t(
                      "AlarmReceptions:edit_emergencycenter_setting_validation_value_required"
                    )
                  }
                />
              )}
            />
          </Row>
        </Column>
      </form>
    </PrimeModal>
  );
});
