import useTranslations from "../../../core/i18n/useTranslations";
import Icon from "../Icon";
import { PopupContextMenuLinkButton } from "../Menus/PopupContextMenu/PopupContextMenuLinkButton";

import styles from "./PhotoWrapperWithUpload.module.css";

export const UpdateDeletePhoto = ({
  photoURL,
  photoData,
  alternativeString,
  removeImg,
  handleUpload,
}: {
  photoURL: string;
  photoData: {
    preview: string;
    raw: string;
  };
  alternativeString?: string;
  removeImg: () => Promise<void>;
  handleUpload: (e: any) => Promise<void>;
}) => {
  const t = useTranslations();
  return (
    <div className={styles.imgContainer}>
      <div className={styles.hoverContent}>
        <Icon name="photograph" color="Primary-700" />
      </div>
      <img
        className={styles.buttonContent}
        src={photoData.preview || photoURL}
        alt={alternativeString ?? "image"}
      />
      <div className={styles.imgPopup}>
        <label className={styles.updateLabel} htmlFor="updatePhoto">
          <Icon name="camera" size={16} />
          <span>{t("Objects:update_photo")}</span>
          <input
            type="file"
            id="updatePhoto"
            onChange={handleUpload}
            accept="image/jpeg"
          />
        </label>
        <PopupContextMenuLinkButton
          icon="trash"
          text={t("Objects:remove_photo")}
          onClick={removeImg}
        />
      </div>
    </div>
  );
};
