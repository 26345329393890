import { useEffect, useRef, useState } from "react";
import PrimeTooltip from "../ui-lib/components/PrimeTooltip/PrimeTooltip";
import usePageState from "../core/pagestate/usePageState";
import classNames from "classnames";

export default function TruncateValue({
  value,
  className,
}: Readonly<{
  value: string;
  className?: string;
}>) {
  const { columnSize } = usePageState();

  const textRef = useRef<HTMLElement>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  useEffect(() => {
    if (textRef.current) {
      setShowTooltip(textRef.current.offsetWidth < textRef.current.scrollWidth);
    }
  }, [columnSize, textRef?.current?.scrollWidth]);
  return (
    <>
      {showTooltip && <PrimeTooltip target={textRef.current!} />}
      <span
        data-pr-tooltip={typeof value === "string" ? value : undefined}
        ref={textRef}
        className={classNames(className, "croped-text w-100")}
      >
        {value}
      </span>
    </>
  );
}
