import { observer } from "mobx-react-lite";
import { addLocale } from "primereact/api";
import { useEffect } from "react";
import useUser from "../user/useUser";
import useLanguage from "./useLanguage";
import { getUserStoredLang } from "../../ui-lib/utils/localeHelper";

const LanguageInitializer = () => {
  const user = useUser();
  const languageStore = useLanguage();

  addLocale("es-ES", {
    dayNames: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
    dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
    monthNames: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    monthNamesShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
    today: "Hoy",
    clear: "Limpiar",
    weekHeader: "Sm",
    firstDayOfWeek: 1, // Assuming Monday is the first day of the week
  });

  addLocale("da-DK", {
    dayNames: [
      "Søndag",
      "Mandag",
      "Tirsdag",
      "Onsdag",
      "Torsdag",
      "Fredag",
      "Lørdag",
    ],
    dayNamesShort: ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"],
    dayNamesMin: ["Sø", "Ma", "Ti", "On", "To", "Fr", "Lø"],
    monthNames: [
      "Januar",
      "Februar",
      "Marts",
      "April",
      "Maj",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "December",
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Maj",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dec",
    ],
    today: "I dag",
    clear: "Ryd",
    weekHeader: "Uge",
    firstDayOfWeek: 1,
  });

  addLocale("fr-FR", {
    dayNames: [
      "dimanche",
      "lundi",
      "mardi",
      "mercredi",
      "jeudi",
      "vendredi",
      "samedi",
    ],
    dayNamesShort: ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
    dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
    monthNames: [
      "janvier",
      "février",
      "mars",
      "avril",
      "mai",
      "juin",
      "juillet",
      "août",
      "septembre",
      "octobre",
      "novembre",
      "décembre",
    ],
    monthNamesShort: [
      "janv.",
      "févr.",
      "mars",
      "avril",
      "mai",
      "juin",
      "juil.",
      "août",
      "sept.",
      "oct.",
      "nov.",
      "déc.",
    ],
    today: "Aujourd'hui",
    clear: "Effacer",
    weekHeader: "Sem",
    firstDayOfWeek: 1, // In France, Monday is the first day of the week
  });

  addLocale("fi-FI", {
    dayNames: [
      "sunnuntai",
      "maanantai",
      "tiistai",
      "keskiviikko",
      "torstai",
      "perjantai",
      "lauantai",
    ],
    dayNamesShort: ["su", "ma", "ti", "ke", "to", "pe", "la"],
    dayNamesMin: ["su", "ma", "ti", "ke", "to", "pe", "la"],
    monthNames: [
      "tammikuu",
      "helmikuu",
      "maaliskuu",
      "huhtikuu",
      "toukokuu",
      "kesäkuu",
      "heinäkuu",
      "elokuu",
      "syyskuu",
      "lokakuu",
      "marraskuu",
      "joulukuu",
    ],
    monthNamesShort: [
      "tammi",
      "helmi",
      "maalis",
      "huhti",
      "touko",
      "kesä",
      "heinä",
      "elo",
      "syys",
      "loka",
      "marras",
      "joulu",
    ],
    today: "Tänään",
    clear: "Tyhjennä",
    weekHeader: "Vk",
    firstDayOfWeek: 1,
  });

  addLocale("de-DE", {
    firstDayOfWeek: 1,
    dayNames: [
      "Sonntag",
      "Montag",
      "Dienstag",
      "Mittwoch",
      "Donnerstag",
      "Freitag",
      "Samstag",
    ],
    dayNamesShort: ["Son", "Mon", "Dien", "Mittw", "Donn", "Frei", "Sam"],
    dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    monthNames: [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mär",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dez",
    ],
    weekHeader: "Wo",
    today: "Heute",
    clear: "Leer",
  });

  addLocale("nb-NO", {
    firstDayOfWeek: 1,
    dayNames: [
      "Søndag",
      "Mandag",
      "Tirsdag",
      "Onsdag",
      "Torsdag",
      "Fredag",
      "Lørdag",
    ],
    dayNamesShort: ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"],
    dayNamesMin: ["Sø", "Ma", "Ti", "On", "To", "Fr", "Lø"],
    monthNames: [
      "Januar",
      "Februar",
      "Mars",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Desember",
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Des",
    ],
    weekHeader: "Uk",
    today: "I dag",
    clear: "Tøm",
  });

  addLocale("sv-SE", {
    firstDayOfWeek: 1,
    dayNames: [
      "söndag",
      "måndag",
      "tisdag",
      "onsdag",
      "torsdag",
      "fredag",
      "lördag",
    ],
    dayNamesShort: ["sön", "mån", "tis", "ons", "tor", "fre", "lör"],
    dayNamesMin: ["S", "M", "T", "O", "T", "F", "L"],
    monthNames: [
      "januari",
      "februari",
      "mars",
      "april",
      "maj",
      "juni",
      "juli",
      "augusti",
      "september",
      "oktober",
      "november",
      "december",
    ],
    monthNamesShort: [
      "jan",
      "feb",
      "mar",
      "apr",
      "maj",
      "jun",
      "jul",
      "aug",
      "sep",
      "okt",
      "nov",
      "dec",
    ],
    weekHeader: "V",
    today: "Idag",
    clear: "Rensa",
  });

  addLocale("en-GB", {
    dayNames: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    today: "Today",
    clear: "Clear",
    weekHeader: "Wk",
    firstDayOfWeek: 0,
  });
  const setupLangs = async () => {
    try {
      // Setup languages
      await user.initialize();
      await languageStore.getLanguageList(user);

      // Setup user languages
      languageStore.setUserLanguages(
        user.config?.primaryLanguage ?? "",
        user.config?.secondaryLanguage ?? ""
      );

      const storedLanguage =
        getUserStoredLang(user)?.language ?? languageStore.getStoredLanguage();
      if (
        storedLanguage === user.config?.primaryLanguage ||
        storedLanguage === user.config?.secondaryLanguage
      ) {
        languageStore.setCurrentLanguage(storedLanguage);
      } else {
        languageStore.setCurrentLanguage(user.config?.primaryLanguage);
      }
    } catch (error: any) {
      console.error("Check 'setupLangs' function");
    }
  };
  useEffect(() => {
    if (user.loggedIn) {
      setupLangs();
    }
  }, [user.loggedIn]);

  return <></>;
};

export default observer(LanguageInitializer);
