import axios from "axios";
import { useEffect, useState } from "react";
import { exportApi } from "../../core/api/common_table";
import { api as reportApi, useReports } from "../../core/api/reports/reports";
import { ReportResponse } from "../../core/api/reports/types";
import { validateEmail } from "../../core/helpers/validation";
import useTranslations from "../../core/i18n/useTranslations";
import useUser from "../../core/user/useUser";
import useLanguage from "../../core/i18n/useLanguage";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import { getErrorKey } from "../Errors/ErrorAlert";
import { SearchFilter } from "../FilterSearch/TableSearch";
import ExportSettings from "./ExportSettings";
import {
  Columns,
  ExportProps,
  ExportTypesSettings,
  PeriodicReportMode,
  PeriodicReportOptions,
} from "./types";
import { notifyApiErrors } from "../../core/helpers/helpers";

const manual: ReportResponse = {
  id: -1,
  reportName: "Manual",
  timespan: false,
};

type ExportObjectsProps = Omit<ExportProps, "settings" | "onExport"> & {
  isOpen: boolean;
  searchFilter?: SearchFilter;
  objectIds?: number[];
  columnInfo?: Columns;
};

interface ExportParams {
  runMode: "ByChosenUsers" | "BySearchCriteria";
  languageSelect?: string;
  userIds?: number[];
  start?: string;
  end?: string;
}

const ExportObjectsSettings = ({
  isOpen,
  labels,
  tableParams,
  searchFilter,
  onClose,
  objectIds,
  columnInfo,
}: ExportObjectsProps) => {
  const t = useTranslations();
  const languageStore = useLanguage();

  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>();
  const [cancelSource, setCancelSource] = useState(axios.CancelToken.source());
  const [downloadInterval, setDownloadInterval] = useState<
    NodeJS.Timeout | undefined
  >();

  const onDone = () => {
    setLoading(false);
    onClose();
  };

  const onError = (error: any) => {
    setLoading(false);

    const errorKey = getErrorKey(error);
    notify({
      message: t(`Errors:${errorKey}`),
      variant: "error",
    });
  };

  const { data: availableReports } = useReports();
  const { authenticatedRequest } = useUser();
  const { exportAsExcel } = exportApi(
    authenticatedRequest,
    "api/v2/users",
    onDone,
    onError
  );
  const { downloadReport, setupPeriodicReport } = reportApi(
    authenticatedRequest,
    onDone,
    onError
  );

  const [selectedReportId, setSelectedReportId] = useState(manual.id);
  const reports = [manual, ...(availableReports ?? [])];
  const selectedReport = reports.find(
    (i) => i.id === selectedReportId
  ) as ReportResponse;

  const [periodicReportOptions, setPeriodicReportOptions] =
    useState<PeriodicReportOptions>({
      mode: PeriodicReportMode.ExportNow,
      dontSendEmptyReports: false,
    });

  const exportTypesSettings: ExportTypesSettings = {
    description: t("Common:export_type_description"),
    items: reports.map((r) => ({
      id: r.id,
      name: t(`Language:Report${r.reportName}`),
      isSelected: r.id === selectedReportId,
    })),
    onSelectItem: (i) => setSelectedReportId(i.id as number),
  };

  const settings = {
    supportDateRange:
      periodicReportOptions.mode === PeriodicReportMode.Periodic // Only base on report type if not periodic
        ? false
        : selectedReport.timespan,
    columnInfo: selectedReportId === manual.id ? columnInfo : undefined,
    exportTypes: exportTypesSettings,
    periodicReports:
      selectedReportId !== manual.id
        ? {
            options: periodicReportOptions,
            setOptions: setPeriodicReportOptions,
          }
        : undefined,
  };

  // Reset any validation errors when values are updated and valid
  useEffect(() => {
    if (validationErrors?.email) {
      const isValid = validateEmail(periodicReportOptions.email);

      if (isValid) {
        setValidationErrors(undefined);
      }
    }
  }, [periodicReportOptions.email]);

  // Effects
  const startDownloadExport = async (params: ExportParams) => {
    const { languageSelect, userIds, start, end } = params;
    const newDownloadInterval = await exportAsExcel({
      tableParams: {
        start,
        end,
        sort: tableParams?.sort,
        filter: tableParams?.filter,
        customer: tableParams?.customer,
        customers: tableParams?.customers,
        userIds: userIds ?? [],
        language: languageSelect,
      },
      searchFilter,
      cancelToken: cancelSource.token,
    });

    setDownloadInterval(newDownloadInterval);
  };

  const startDownloadReport = async (params: ExportParams) => {
    const { runMode, languageSelect, userIds, start, end } = params;
    const newDownloadInterval = await downloadReport({
      reportId: selectedReportId,
      language: languageSelect,
      reportArguments: {
        registrationTimeStart: start,
        registrationTimeEnd: end,
        start,
        end,
        runMode,
        entityIds: userIds ?? [],
        organizationIds:
          tableParams?.customers?.split(",").map((i) => +i) ?? [],
        searchText: searchFilter?.value ?? "",
        filterType: tableParams?.filter?.[0]?.id,
        filterValue: tableParams?.filter?.[0]?.value as string,
        filterType2: tableParams?.filter?.[1]?.id,
        filterValue2: tableParams?.filter?.[1]?.value as string,
        filterType3: tableParams?.filter?.[2]?.id,
        filterValue3: tableParams?.filter?.[2]?.value as string,
        filterType4: tableParams?.filter?.[3]?.id,
        filterValue4: tableParams?.filter?.[3]?.value as string,
      },
      cancelToken: cancelSource.token,
    });

    setDownloadInterval(newDownloadInterval);
  };

  const setupPeriodicReportSetup = async (params: ExportParams) => {
    const { runMode, userIds, start, end } = params;

    if (!validateEmail(periodicReportOptions.email)) {
      setLoading(false);
      setValidationErrors({
        email: t("Errors:input_email_format"),
      });

      return;
    }

    try {
      await setupPeriodicReport({
        reportId: selectedReportId,
        email: periodicReportOptions.email ?? "",
        comment: periodicReportOptions.comment,
        dontSendEmptyReports:
          periodicReportOptions.dontSendEmptyReports ?? false,
        language: periodicReportOptions.languange,
        periodicity: periodicReportOptions.frequency ?? 0,
        reportArguments: {
          registrationTimeStart: start,
          registrationTimeEnd: end,
          start,
          end,
          runMode,
          entityIds: userIds ?? [],
          organizationIds:
            tableParams?.customers?.split(",").map((i) => +i) ?? [],
          searchText: searchFilter?.value ?? "",
          filterType: tableParams?.filter?.[0]?.id,
          filterValue: tableParams?.filter?.[0]?.value as string,
          filterType2: tableParams?.filter?.[1]?.id,
          filterValue2: tableParams?.filter?.[1]?.value as string,
          filterType3: tableParams?.filter?.[2]?.id,
          filterValue3: tableParams?.filter?.[2]?.value as string,
          filterType4: tableParams?.filter?.[3]?.id,
          filterValue4: tableParams?.filter?.[3]?.value as string,
        },
        cancelToken: cancelSource.token,
      });

      notify({ message: t("Reports:report_scheduled"), variant: "info" });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <ExportSettings
      isOpen={isOpen}
      labels={{
        ...labels,
        submit:
          periodicReportOptions.mode === PeriodicReportMode.Periodic
            ? t("Common:report_save")
            : labels.submit,
      }}
      settings={settings}
      tableParams={tableParams}
      state={{
        loading,
        validationErrors,
      }}
      onClose={() => {
        if (downloadInterval) {
          window.clearInterval(downloadInterval);
          setDownloadInterval(undefined);
        }
        cancelSource.cancel();
        setCancelSource(axios.CancelToken.source());
        setLoading(false);
        onClose();
      }}
      onExport={async (args) => {
        try {
          setValidationErrors(undefined);
          setLoading(true);

          const runMode = objectIds ? "ByChosenUsers" : "BySearchCriteria";
          const languageSelect = languageStore.getStoredLanguage() ?? undefined;

          const userIds = objectIds;
          const start = args.timeSpan?.start?.toISOString();
          const end = args.timeSpan?.end?.toISOString();

          if (selectedReportId === -1) {
            await startDownloadExport({
              runMode,
              languageSelect,
              userIds,
              start,
              end,
            });
          } else if (
            periodicReportOptions.mode === PeriodicReportMode.ExportNow
          ) {
            await startDownloadReport({
              runMode,
              languageSelect,
              userIds,
              start,
              end,
            });
          } else {
            await setupPeriodicReportSetup({ runMode, userIds, start, end });
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            return;
          }

          const errorKey = getErrorKey(error);
          notify({ message: t(`Errors:${errorKey}`), variant: "error" });

          setLoading(false);
        }
      }}
      isLoading={loading}
      dataCy="objects_export_modal_container"
    />
  );
};

export default ExportObjectsSettings;
