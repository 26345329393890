import classNames from "classnames";
import useTranslations from "../core/i18n/useTranslations";
import Button from "../ui-lib/components/Button/Button";
import usePageState from "../core/pagestate/usePageState";
import {
  breakpoints,
  useWindowDimensions,
} from "../core/hooks/dimensionProvider";
import { useSaveObject } from "../core/SaveObjectContext/hooks";

export default function ContextSaveButton() {
  const t = useTranslations();
  const pageState = usePageState();
  const { width } = useWindowDimensions();
  const { canSave, isDirty, isSaving, onSave } = useSaveObject();

  if (pageState.hideSaveButton) {
    return null;
  }

  return (
    <div
      className={classNames({ ["save-button"]: width < breakpoints.tablet })}
    >
      <Button
        text={t("Common:save")}
        disabled={!isDirty || !canSave}
        loading={isSaving}
        onClick={onSave}
      />
    </div>
  );
}
