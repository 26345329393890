import {
  breakpoints,
  useWindowDimensions,
} from "../../core/hooks/dimensionProvider";
import { AlignType, ColType, Column, Row, RowType } from "../Layout/Layout";

export const ResponsiveWrapper = ({
  rowAlign,
  rowType,
  children,
  rowStyle,
  colAlign,
  colType,
  colStyle,
  style,
  className,
  breakPoint,
}: {
  rowAlign?: AlignType;
  rowType?: RowType;
  rowStyle?: React.CSSProperties;
  colAlign?: AlignType;
  colType?: ColType;
  colStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  className?: string;
  children: React.ReactNode;
  breakPoint?: number;
}) => {
  const { width } = useWindowDimensions();
  return width < (breakPoint ?? breakpoints.desktop) ? (
    <Column
      className={className}
      align={colAlign}
      type={colType}
      style={style ?? colStyle}
    >
      {children}
    </Column>
  ) : (
    <Row
      className={className}
      align={rowAlign}
      type={rowType}
      style={style ?? rowStyle}
    >
      {children}
    </Row>
  );
};
