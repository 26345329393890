import { useRef, useState } from "react";
import { ControllerRenderProps, FieldValues } from "react-hook-form";
import {
  AutoComplete,
  AutoCompleteCompleteEvent,
} from "primereact/autocomplete";
import classNames from "classnames";
import Icon from "../Icon";
import useTranslations from "../../../core/i18n/useTranslations";
import { IAutocompleteField } from "../../../core/api/autocomplite/types";

import styles from "./Autocomplite.module.css";
import inputStyles from "../Inputs/Input.module.css";

interface IAutocompleteProps {
  field: ControllerRenderProps<FieldValues, string>;
  fieldData?: IAutocompleteField;
  defaultLabel: string;
  defaultPlaceholder?: string;
  validationError?: string | null;
}

const Autocomplite = ({
  field,
  fieldData,
  defaultLabel,
  defaultPlaceholder,
  validationError,
}: IAutocompleteProps) => {
  const t = useTranslations();
  const multiChoice = fieldData?.inputType === "MultiChoice";
  const singleChoice = fieldData?.inputType === "SingleChoice";
  const multiChoiceFreeText = fieldData?.inputType === "MultiChoiceFreeText";
  const isMultiChoice = multiChoice || singleChoice || multiChoiceFreeText;
  const multiInputRef = useRef<HTMLInputElement>(null);

  const [items, setItems] = useState<string[]>(fieldData?.alternatives ?? []);

  const clearMultiInput = () => {
    if (multiInputRef.current) {
      multiInputRef.current.value = "";
    }
  };

  const filterItems = (event: AutoCompleteCompleteEvent) => {
    let query = event.query;
    let filteredItems = [];

    for (const element of fieldData?.alternatives ?? []) {
      if (element.toLowerCase().startsWith(query.toLowerCase())) {
        filteredItems.push(element);
      }
    }

    setItems(filteredItems);
  };

  const multiChoiceFreeTextHandler = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      const target = e.target as HTMLInputElement;
      if (!target.value.trim()) return;

      const parsedValue: string[] = field.value
        ? field.value.toLowerCase().split("#")
        : [];

      if (parsedValue.includes(target.value.toLowerCase())) {
        clearMultiInput();
        return;
      }
      field.onChange(
        [...(field.value?.split("#") ?? []), target.value].join("#")
      );
      clearMultiInput();
    }
  };

  return (
    <div
      className={classNames(styles.autocompliteWrapper, { ["w-100"]: true })}
    >
      <label htmlFor={field.name}>{fieldData?.label ?? defaultLabel}</label>
      <AutoComplete
        inputRef={multiInputRef}
        removeTokenIcon={"pi pi-times"}
        showEmptyMessage={!!fieldData?.alternatives.length}
        delay={0}
        emptyMessage={t("Common:no_options")}
        multiple={isMultiChoice}
        selectionLimit={singleChoice ? 1 : undefined}
        placeholder={
          (multiChoice || multiChoiceFreeText) && field.value?.length
            ? ""
            : fieldData?.inlineHelp ?? defaultPlaceholder
        }
        className={classNames({
          [styles.freeText]: true,
          [styles.multiSelect]: isMultiChoice,
          [styles.singleChoiceDisabled]: singleChoice && field.value,
          [styles.filledBorder]: !!field.value,
          [styles.validationError]: !!validationError,
        })}
        inputId={field.name}
        value={isMultiChoice ? field.value?.split("#") : field.value}
        suggestions={items}
        completeMethod={filterItems}
        onChange={(e) => {
          if (isMultiChoice) {
            field.onChange(e.value?.length ? e.value?.join("#") : null);
            return;
          }
          field.onChange(e.value);
        }}
        onBlur={clearMultiInput}
        onKeyDown={(e) => {
          if (multiChoiceFreeText) multiChoiceFreeTextHandler(e);
        }}
      />
      {validationError && (
        <div className={inputStyles.validationError}>
          <Icon name="exclamation-circle" size={14} />
          {validationError}
        </div>
      )}
    </div>
  );
};

export default Autocomplite;
