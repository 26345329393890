import { useEffect, useRef, useState } from "react";
import { Row, Spacer } from "../../components/Layout/Layout";
import Button, { ButtonVariant } from "../../ui-lib/components/Button/Button";
import {
  KnownLocationListModel,
  NewLocationResponseData,
} from "../../core/api/locations/types";
import { notifyApiErrors } from "../../core/helpers/helpers";
import useTranslations from "../../core/i18n/useTranslations";
import { EditLocationActions } from "./LocationEditForm";
import { KnownLocation } from "../../core/api/objects/types";
import TableCell from "../../ui-lib/components/Tables/TableCell";
import Table, { TableColumn } from "../../ui-lib/components/Tables/Table";
import {
  addLocationToObject,
  getKnownLocations,
} from "../../core/api/locations/locations";
import useUser from "../../core/user/useUser";
import SearchInput from "../../ui-lib/components/Inputs/SearchInput";
import Checkbox from "../../ui-lib/components/Checkbox/Checkbox";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import { CreateNewLocationModal } from "./CreateNewLocationModal";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";
import {
  breakpoints,
  useWindowDimensions,
} from "../../core/hooks/dimensionProvider";

const columns = (
  t: (key: string) => string,
  isMobile: boolean,
  preselectedLocations: number[],
  setSelectedLocationsId: (id?: number) => void,
  selectedLocationsId?: number
) =>
  [
    {
      header: t("Locations:table_columns_Description"),
      fieldTemplate: (rowData: KnownLocationListModel) => (
        <TableCell value={rowData.description} />
      ),
    },
    {
      header: t("Locations:table_columns_KLType"),
      fieldTemplate: (rowData: KnownLocationListModel) => (
        <TableCell
          leftIcon="clipboard-list"
          iconColor="Grey-300"
          iconSize={16}
          value={t(`Locations:BeaconType_${rowData.klType}`)}
        />
      ),
    },
    {
      header: t("Locations:table_columns_CustomerName"),
      fieldTemplate: (rowData: KnownLocationListModel) => (
        <TableCell
          leftIcon="office-building"
          iconColor="Grey-300"
          iconSize={16}
          value={rowData.organizationName}
        />
      ),
    },
    {
      header: t("Locations:table_columns_Marking"),
      fieldTemplate: (rowData: KnownLocationListModel) =>
        rowData.marking ? (
          <TableCell
            leftIcon="tag"
            iconColor="Grey-300"
            iconSize={16}
            value={rowData.marking}
          />
        ) : null,
    },
    {
      frozen: isMobile,
      alignFrozen: "right",
      className: isMobile ? "action-column" : "",
      editable: true,
      style: { minWidth: "50px", width: "50px" },
      fieldTemplate: (rowData: KnownLocationListModel) => (
        <Checkbox
          disabled={preselectedLocations.indexOf(rowData.id) !== -1}
          checked={
            rowData.id === selectedLocationsId ||
            preselectedLocations.indexOf(rowData.id) !== -1
          }
          label=""
          innerStyle={{ justifyContent: "flex-end" }}
          onChange={() => {
            setSelectedLocationsId(
              selectedLocationsId === rowData.id ? undefined : rowData.id
            );
          }}
        />
      ),
    },
  ] as TableColumn<KnownLocationListModel>[];

export const CreateLocationModal = ({
  onSubmit,
  onClose,
  modalTitle,
  variant = "primary",
  modalButtonIcon,
  saveButtonTitle,
  modalButtonTitle,
  linkedLocations = [],
  objectId,
}: {
  onSubmit?: (result?: NewLocationResponseData) => void;
  onClose?: () => void;
  modalTitle: string;
  variant: ButtonVariant;
  modalButtonIcon?: string;
  saveButtonTitle: string;
  modalButtonTitle: string;
  linkedLocations: KnownLocation[];
  objectId?: string;
}) => {
  const t = useTranslations();
  const editLocationRef = useRef<EditLocationActions>(null);
  const { authenticatedRequest, config } = useUser();
  const viserPermission = config?.show.includes("SensioHideFields");
  const [isNewLocationOpen, setIsNewLocationOpen] = useState(false);
  const [isLocationFromStockOpen, setIsLocationFromStockOpen] = useState(false);
  const [locations, setLocations] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLocationAdded, setIsLocationAdded] = useState(false);
  const [preselectedLocations, setPreselectedLocations] = useState<number[]>(
    linkedLocations.map((loc) => loc.knownLocationId)
  );
  const [selectedLocationsId, setSelectedLocationsId] = useState<
    number | undefined
  >();
  const { width } = useWindowDimensions();
  const isMobile = width < breakpoints.desktop;

  const closeModal = () => {
    setIsLocationAdded(false);
    setIsLocationFromStockOpen(false);
    setSearchQuery("");
    editLocationRef.current?.reset();
    if (isLocationAdded) {
      onClose?.();
    }
  };

  const fetchLocations = async () => {
    try {
      setIsLoading(true);
      const results = await getKnownLocations(
        { q: searchQuery, o: "ORGANIZATION_ASC", page: 1, pageSize: 200 },
        authenticatedRequest
      );
      setLocations(results.knownLocations);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const linkLocationFromStock = async () => {
    try {
      setIsLoading(true);
      await addLocationToObject(
        objectId!,
        selectedLocationsId as number,
        authenticatedRequest
      );
      setPreselectedLocations([...preselectedLocations, selectedLocationsId!]);
      setSelectedLocationsId(undefined);
      if (!isLocationAdded) {
        setIsLocationAdded(true);
      }
      notify({
        message: t("Locations:location_added"),
      });
      setIsLocationFromStockOpen(false);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, [searchQuery]);

  useEffect(() => {
    if (isLocationFromStockOpen) {
      setPreselectedLocations(
        linkedLocations.map((loc) => loc.knownLocationId)
      );
    }
  }, [isLocationFromStockOpen]);

  return (
    <>
      <Button
        variant={variant || "primary"}
        text={modalButtonTitle}
        image={modalButtonIcon}
        onClick={() => {
          setIsLocationFromStockOpen(true);
        }}
      />
      <PrimeModal
        isOpen={isLocationFromStockOpen}
        onClose={closeModal}
        withHeader
        header={modalTitle}
        withFooter
        submitBtn={{
          text: saveButtonTitle,
          onClick: linkLocationFromStock,
          disabled: !selectedLocationsId || isLoading,
          loading: isLoading,
        }}
        cancelBtn={{
          text: t("Common:cancel"),
          onClick: closeModal,
        }}
      >
        <p>{t("Locations:add_modal_description")}</p>

        <Spacer size={16} />

        <SearchInput
          placeholder={t("Locations:search_stock_location_placeholder")}
          value={searchQuery ?? ""}
          limit={1}
          onChange={(value) => {
            setSearchQuery(value);
          }}
        />

        <Spacer size={36} />

        <Row type="space" align="center">
          <h2>{t("Locations:locations_in_stock")}</h2>
          {!viserPermission && (
            <Button
              image="location-marker"
              variant="secondary"
              text={t("Locations:add_location")}
              onClick={() => {
                setIsNewLocationOpen(true);
              }}
            />
          )}

          <CreateNewLocationModal
            onClose={() => {
              setIsNewLocationOpen(false);
            }}
            onSubmit={onSubmit}
            isOpened={isNewLocationOpen}
          />
        </Row>
        <Spacer size={16} />
        <Table<KnownLocationListModel>
          columns={columns(
            t,
            isMobile,
            preselectedLocations,
            setSelectedLocationsId,
            selectedLocationsId
          )}
          items={locations}
          rowActionContextMenu
          hideEmptyMessage
          noRowsMessage={t("Locations:locations_table_not_instock")}
          showRowHover
          withShowMore
          rowActionsFixed={isMobile}
        />
      </PrimeModal>
    </>
  );
};
