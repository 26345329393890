import { Controller, useFormContext } from "react-hook-form";
import {
  Column,
  ResponsiveRow,
  Spacer,
} from "../../../components/Layout/Layout";
import { validateEmailIfExists } from "../../../core/helpers/validation";
import Autocomplite from "../../../ui-lib/components/Autocomplite/Autocomplite";
import useTranslations from "../../../core/i18n/useTranslations";
import useUser from "../../../core/user/useUser";
import TextInput from "../../../ui-lib/components/Inputs/TextInput";
import Checkbox from "../../../ui-lib/components/Checkbox/Checkbox";
import TextArea from "../../../ui-lib/components/Inputs/Textarea";
import useSharedObject from "../SharedObjectContext/useSharedObject";

const ObjectGeneralPersonalDetails = () => {
  const t = useTranslations();
  const { config } = useUser();
  const { objectFields, fieldsRules, getErrorMessage } = useSharedObject();

  const {
    formState: { errors },
    setValue,
    control,
  } = useFormContext();

  const hasShowObjectDetailsVariant1Permission = config?.show.includes(
    "ShowObjectDetailsVariant1"
  );

  return (
    <>
      {!config?.show.includes("CareiumHideFields") && (
        <>
          <h2>{t("Objects:general_personal_details_title")}</h2>
          <p>{t("Objects:general_personal_details_description")}</p>

          <Spacer size={16} />

          {!hasShowObjectDetailsVariant1Permission && (
            <>
              <ResponsiveRow align="start">
                <Controller
                  name="title"
                  control={control}
                  rules={fieldsRules.title}
                  render={({ field }) => (
                    <Autocomplite
                      fieldData={objectFields?.[field.name]}
                      defaultPlaceholder={t("Common:placeholder_object_title")}
                      defaultLabel={t("Common:labels_title")}
                      field={field}
                      validationError={
                        errors?.title &&
                        getErrorMessage(
                          errors.title.type as string,
                          fieldsRules[field.name]?.maxLength?.toString()
                        )
                      }
                    />
                  )}
                />
                <Spacer />
                <Controller
                  name="profession"
                  control={control}
                  rules={fieldsRules.profession}
                  render={({ field }) => (
                    <Autocomplite
                      fieldData={objectFields?.[field.name]}
                      defaultPlaceholder={t("Common:placeholder_profession")}
                      defaultLabel={t("Common:labels_profession")}
                      field={field}
                      validationError={
                        errors?.profession &&
                        getErrorMessage(
                          errors.profession.type as string,
                          fieldsRules[field.name]?.maxLength?.toString()
                        )
                      }
                    />
                  )}
                />
              </ResponsiveRow>

              <Spacer size={16} />
            </>
          )}

          <ResponsiveRow align="start">
            <Controller
              name="email"
              control={control}
              rules={{
                maxLength: 60,
                validate: validateEmailIfExists,
              }}
              render={({ field }) => (
                <TextInput
                  label={t("Common:email")}
                  inputRef={field.ref}
                  value={field.value}
                  onChange={field.onChange}
                  placeholder={t("Common:placeholder_email")}
                  validationError={
                    errors?.email && t("Errors:input_email_format")
                  }
                />
              )}
            />
            <Spacer />
            <Controller
              name="nationalIdNumber"
              control={control}
              render={({ field }) => (
                <TextInput
                  label={t("Common:labels_id_number")}
                  value={field.value}
                  onChange={(e) => {
                    setValue(field.name, e.target.value.toUpperCase());
                  }}
                />
              )}
            />
          </ResponsiveRow>
          <Spacer size={16} />
        </>
      )}

      <ResponsiveRow align="start">
        <Column>
          {!hasShowObjectDetailsVariant1Permission && (
            <Controller
              name="externalId"
              control={control}
              render={({ field }) => (
                <TextInput
                  label={t("Common:labels_external_id")}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          )}
        </Column>
        <Spacer />
        <Column align="start">
          {!config?.show.includes("CareiumHideFields") && (
            <Controller
              name="protectedIdentidy"
              control={control}
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  onChange={field.onChange}
                  style={{ padding: 0 }}
                  label={t("Common:labels_protected_identity")}
                />
              )}
            />
          )}
        </Column>
      </ResponsiveRow>
      {!config?.show.includes("CareiumHideFields") && (
        <>
          <Spacer size={16} />
          <Controller
            name="additionalInfo"
            control={control}
            rules={fieldsRules.additionalInfo}
            render={({ field }) => (
              <Autocomplite
                fieldData={objectFields?.[field.name]}
                defaultLabel={t("Common:labels_additional_info")}
                field={field}
                validationError={
                  errors.additionalInfo &&
                  getErrorMessage(
                    errors.additionalInfo.type as string,
                    fieldsRules[field.name]?.maxLength?.toString()
                  )
                }
              />
            )}
          />

          <Spacer size={16} />

          <Controller
            name="medicalCondition"
            rules={fieldsRules.medicalCondition}
            control={control}
            render={({ field }) => (
              <Autocomplite
                fieldData={objectFields?.[field.name]}
                defaultLabel={t("Common:labels_condition")}
                field={field}
                validationError={
                  errors?.medicalCondition &&
                  getErrorMessage(
                    errors.medicalCondition.type as string,
                    fieldsRules[field.name]?.maxLength?.toString()
                  )
                }
              />
            )}
          />

          <Spacer size={16} />

          <Controller
            name="medication"
            rules={fieldsRules.medication}
            control={control}
            render={({ field }) => (
              <Autocomplite
                fieldData={objectFields?.[field.name]}
                defaultLabel={t("Common:labels_medication")}
                field={field}
                validationError={
                  errors?.medication &&
                  getErrorMessage(
                    errors.medication.type as string,
                    fieldsRules[field.name]?.maxLength?.toString()
                  )
                }
              />
            )}
          />
        </>
      )}
      {!hasShowObjectDetailsVariant1Permission &&
        !config?.show.includes("CareiumHideFields") && (
          <>
            <Spacer size={16} />

            <Controller
              name="carePlan"
              rules={{ maxLength: 1000 }}
              control={control}
              render={({ field }) => (
                <TextArea
                  label={t("Common:labels_care_plan")}
                  inputRef={field.ref}
                  value={field.value}
                  onChange={field.onChange}
                  validationError={
                    errors?.carePlan &&
                    t("Errors:max_chars_exceeded").replace("{0}", "1000")
                  }
                />
              )}
            />
          </>
        )}

      {!hasShowObjectDetailsVariant1Permission &&
        !config?.show.includes("CareiumHideFields") && (
          <>
            <Spacer size={16} />

            <Controller
              name="supportingAids"
              control={control}
              rules={fieldsRules.supportingAids}
              render={({ field }) => (
                <Autocomplite
                  fieldData={objectFields?.[field.name]}
                  defaultLabel={t("Common:labels_supporting_aids")}
                  field={field}
                  validationError={
                    errors?.supportingAids &&
                    getErrorMessage(
                      errors.supportingAids.type as string,
                      fieldsRules[field.name]?.maxLength?.toString()
                    )
                  }
                />
              )}
            />
          </>
        )}
    </>
  );
};

export default ObjectGeneralPersonalDetails;
